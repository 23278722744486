import React from 'react';
import "./Loader.css";

export default class Loader extends React.Component{
	render(){
		return(
			// <div className="pF wp100 hp100 t0 l0 bgF z18">
			// 	<div className="w100 h40 pF t0 bm0 r0 l0 mA c1 taC"><div className="load-wrapp" id="load-wrapp"> <div className="line dIb vM bgSg bd1Db p4 brp50 m2"></div> <div className="line dIb vM bgSg bd1Db p4 brp50 m2"></div> <div className="line dIb vM bgSg bd1Db p4 brp50 m2"></div> </div></div>
			// </div>
			<div className="pF wp100 hp100 t0 l0 bgF z18 fadeInOut">
				<div className="aiC dF fdC bgL hp100 jcC wp100">
					<img src="https://img1.junaroad.com//assets/images/mobileNotif/img-1624349294032.jpg" alt="lr logo" className="lr-icon-logo" />
				</div>
			</div>
		)
	}
}
