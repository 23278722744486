// console.log("actions");
export const CHANGE_NAME = 'CHANGE_NAME';
export const CHANGE_SURNAME = 'CHANGE_SURNAME';
export const SET_VIDEO_URL = 'SET_VIDEO_URL';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_COLOR_VARIANT = 'ADD_COLOR_VARIANT';
export const CLONE_PRODUCT = 'CLONE_PRODUCT';
export const CHANGE_PRODUCT_VALUE = 'CHANGE_PRODUCT_VALUE';
export const CHANGE_PRODUCT_MULTIVALUE = 'CHANGE_PRODUCT_MULTIVALUE';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_IMAGE = "DELETE_IMAGE";
export const ADD_SIZE = "ADD_SIZE";
export const CHANGE_SIZE = "CHANGE_SIZE";
export const DELETE_SIZE = "DELETE_SIZE";
export const CHANGE_LOADING_STATE = 'CHANGE_LOADING_STATE';
export const RESET_PRODUCT = 'RESET_PRODUCT';