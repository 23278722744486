const axios = require('axios');
const _show2Digit = (input) => {
	input = parseInt(input);
	input = ((input < 10) ? '0'+input : input);
	return input;
}
const timeStampToDate = (timestamp) => {
	if(!timestamp) {
		return;
	}
	let shortMonth = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
	date =  new Date(timestamp), AMStr = 'AM', hours = parseInt(date.getHours());
	if(hours >= 12) {
		AMStr = 'PM';
		hours = (hours === 12) ? 12 : _show2Digit(hours % 12);
	} else {
		hours = _show2Digit(hours);
	}
	date = _show2Digit(date.getDate())+' '+shortMonth[date.getMonth()]+' '+date.getFullYear()+', '+hours+':'+_show2Digit(date.getMinutes())+' '+AMStr;
	return date;
}
const insertCommaInNumber = (number) => {
	let nStr = number.toString(), nLen = nStr.length;
	//console.log('number-----', number);
	if(nLen < 4) {
		return nStr;
	} else {
		nStr = nStr.split('').reverse().join('');
		let addCommas = "";
		for(let i = 0 ; i < nLen ; i++) {
			addCommas += nStr[i].toString();
			if(i >= 2 && i % 2 == 0 && i != nLen - 1) {
				addCommas += ',';
			}
			//console.log(addCommas);
		}
		addCommas = addCommas.split('').reverse().join('');
		return addCommas;
	}
}
const timeperiodGenerator = (key) => {
	let date = new Date();
	let month = date.getMonth()+1;
	let year = date.getFullYear();
	month = (month < 10) ? `0${month}` : month.toString();
	if(key === "month") {
		return { month: `${year}-${month}`};
	} else if (key === "six_months"){
		let intMonth = parseInt(month);
		intMonth = intMonth-6;
		if(intMonth < 0) {
			intMonth = (intMonth + 13) % 12;
			year -= 1;
		}
		intMonth = (intMonth < 10) ? `0${intMonth}` : intMonth.toString();
		return {
			min: `${year}-${intMonth}`,
			max: `${date.getFullYear()}-${month}`
		}
	} else {
		let pastyear = year-1;
		let pastmonth = parseInt(month)+1;
		if(month == "12") {
			pastmonth = 1;
			pastyear+=1;
		}
		pastmonth = (pastmonth < 10) ? `0${pastmonth}` : pastmonth.toString();
		return {
			min: `${pastyear}-${pastmonth}`,
			max: `${year}-${month}`
		}
	}
}
const logServerEvent = (ev, datao, dataf) => {
	let dataOrigin = {type: "", val: "", extra: ""},dataFinal = {type: "", val: "", extra: ""};
	dataOrigin = {...dataOrigin, ...datao};
	dataFinal = {...dataFinal, ...dataf};
	return axios.post('/log_server_event', {
		ev_name: ev.name,
		do_type: dataOrigin.type,
		do_val: dataOrigin.val,
		do_extra: dataOrigin.extra,
		df_type: dataFinal.type,
		df_val: dataFinal.val,
		df_extra: dataFinal.extra,
	});
}
const _capitalizeString = (str) => {
	if(!!str) {
		str = str.toLowerCase();
		let cap_str = "";
		str.split(" ").map(word => {
			if(word.length > 0) {
				cap_str += `${word.charAt(0).toUpperCase()}${word.slice(1)} `;
			}
		});
		return cap_str.trim();
	} else {
		return "";
	}
}
const _getCookieValue = (name) => {
	let nameEQ = name + "=", ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
module.exports = {
  timeStampToDate: timeStampToDate,
  insertCommaInNumber: insertCommaInNumber,
  timeperiodGenerator: timeperiodGenerator,
  logServerEvent: logServerEvent,
  _capitalizeString: _capitalizeString,
  _getCookieValue: _getCookieValue
};
