import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types"
import { BrowserRouter, Route } from "react-router-dom"
import App from "../components/App"
import {fetchFirebaseToken} from '../functions/FCMToken';

document.addEventListener("DOMContentLoaded", e => {
	ReactDOM.render(
		<BrowserRouter>
			<Route path="/" component={App} />
		</BrowserRouter>
		, document.getElementById('main'));
});

window.addEventListener('load', () => {
	let service_worker_url = "/service_worker.js";
	if(service_worker_url) {
		navigator && navigator.serviceWorker && navigator.serviceWorker.register && (typeof(navigator.serviceWorker.register) === 'function') && navigator.serviceWorker.register(service_worker_url)
		.then(registration => {
			if(window.SellerFirebaseAnalytics && window.SellerFirebaseAnalytics.logEvent) {
				window.SellerFirebaseAnalytics.logEvent("sw_registration_success",{});
			}
			fetchFirebaseToken(registration);
		});
	}
});

window.addEventListener("beforeinstallprompt", function(event) {
	event.preventDefault();
});