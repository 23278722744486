const _isIOSCheck = () => {
    if(!window.navigator) {
        return false;
    }
    const standalone = window.navigator.standalone, userAgent = window.navigator.userAgent ? window.navigator.userAgent.toLowerCase() : false, 
    safari = (userAgent) ? /safari/.test(userAgent) : false, ios = (userAgent) ? /iphone|ipod|ipad/.test(userAgent) : false; 
    if(ios) { 
        if (!standalone && safari) { 
            //browser
            //alert('browser');
            return true; 
        } else if (standalone && !safari) { 
            //standalone 
            //alert('standalone'); 
            return false; 
        } else if (!standalone && !safari) { 
            //uiwebview 
            //alert('uiwebview');
            return false; 
        }
    } else { 
        //not iOS 
        //alert('not iOS');
        return true; 
    }
}

const permissionPopupCheckOnLogin = () => {
    const appTypeCheck = !document.referrer.includes('android-app://'),
    isIOSCheck = _isIOSCheck(),
    permissionCheck = !!window.Notification && window.Notification.permission === 'denied',
    cookieCheck = document.cookie.indexOf('permission_popup=') === -1,
    websiteHostCheck = ((window.location.href.indexOf("seller.trunkroute.com") > 0) || (window.location.href.indexOf("limeroadlive.com") > 0));
    //websiteHostCheck = window.location.href.indexOf("seller-aviral.trunkroute.com") > 0;
    if(appTypeCheck && permissionCheck && cookieCheck && isIOSCheck && websiteHostCheck) {
        let now = new Date(), time = now.getTime(), expireTime = time + 36*60*60*1000;
        now.setTime(expireTime);
        //console.log('permission popup time - ', now.toUTCString());
        document.cookie = `permission_popup=shown ; expires=${now.toUTCString()} ; path=/`;
        if(window.SellerFirebaseAnalytics && window.SellerFirebaseAnalytics.logEvent) {
            window.SellerFirebaseAnalytics.logEvent('permission_popup_shown', {});
        }
    }
    return appTypeCheck && permissionCheck && cookieCheck && isIOSCheck && websiteHostCheck;
}

const _tokenGenerateAllow = (registration) => {
    const appTypeCheck = registration && window.firebaseMsgApp && !document.referrer.includes('android-app://'),
    isIOSCheck = _isIOSCheck(),
    showAfterLogin = document.cookie.indexOf('loginKey=') > -1,
    websiteHostCheck = ((window.location.href.indexOf("seller.trunkroute.com") > 0) || (window.location.href.indexOf("limeroadlive.com") > 0));
    //websiteHostCheck = window.location.href.indexOf("seller-aviral.trunkroute.com") > 0;
    return appTypeCheck && showAfterLogin && isIOSCheck && websiteHostCheck;
}

const fetchFirebaseToken = (registration) => {
    if(_tokenGenerateAllow(registration)) {
        const messaging = window.firebaseMsgApp && window.firebaseMsgApp.messaging && (typeof(window.firebaseMsgApp.messaging) === 'function') && window.firebaseMsgApp.messaging();
        messaging && messaging.getToken({
            vapidKey: 'BABmbuGxB3ni499eXXg-xZ_3mqjfwh4FspUzFk6iXOSuWAd4qXalvASrzXtBmFjMu8uSm2-7q--zRQ0qargb104',
            serviceWorkerRegistration: registration
        }).then((currentToken) => {
            console.log('currentToken Received : ', currentToken);
            // set token in cookie
            let now = new Date(), time = now.getTime(), expireTime = time + 760*60*60*1000;
            now.setTime(expireTime);
            console.log(now.toUTCString());
            document.cookie = `fcm_token=${currentToken}; expires=${now.toUTCString()} ; path=/`;
            //alert(currentToken);
            messaging.onMessage((payload) => {
                // console.log('Message received. ', payload);
                // console.log('Message Notification received. ', payload.notification);
                //const notifData = payload && payload.data;
                console.log('payload--- ', payload);
                const title = payload && payload.notification && payload.notification.title || "",
                body = payload && payload.notification && payload.notification.body || "",
                url = payload && payload.data && payload.data.url || "",
                image = payload && payload.data && payload.data.image || "https://img1.junaroad.com//assets/images/mobileNotif/img-1620369105152.jpg";
                if(payload) {
                    const notifOpt = {
                        body,
                        icon: image,
                        tag: Date.now(),
                        data: {url}
                    }
                    if(registration && registration.showNotification) {
                        registration.showNotification(title, notifOpt);
                        console.log('notif build by 1');
                        if(window.SellerFirebaseAnalytics && window.SellerFirebaseAnalytics.logEvent) {
                            window.SellerFirebaseAnalytics.logEvent("notif_shown_1", payload);
                        }
                    } else if (Notification && Notification.permission === 'granted') {
                        const notif = new Notification(title, notifOpt);
                        console.log('notif build by 2');
                        if(window.SellerFirebaseAnalytics && window.SellerFirebaseAnalytics.logEvent) {
                            window.SellerFirebaseAnalytics.logEvent("notif_shown_2", payload);
                        }
                    }
                }
            });
        }).catch((error) => {
            console.log('currentToken Error : ', error);
        })
    }
}

module.exports = {
    permissionPopupCheckOnLogin,
    fetchFirebaseToken,
    _isIOSCheck
}