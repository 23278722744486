import React, { Suspense } from "react";
import axios from 'axios';
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { Provider } from "react-redux";
import reducer from "../store/reducer";
import { createStore } from "redux";
import "../packs/App.css"
import "../components/App.css"
import { logServerEvent, _getCookieValue } from '../../javascript/functions/JSFunctions';

// console.log(reducer,"Reducer",reducer);
const store = createStore(reducer,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const Vendor = React.lazy(() => import("../components/Vendor"));
const LoginBuilder = React.lazy(() => import("../containers/LoginBuilder/LoginBuilder"));
const SelectVendorBuilder = React.lazy(() => import("../containers/SelectVendorBuilder/SelectVendorBuilder"));
const Layout = React.lazy(() => import("../hoc/Layout/Layout"));
const PanelLayout = React.lazy(() => import("../hoc/Layout/PanelLayout"));
const ProductQualityDashboardBuilder = React.lazy(() => import("../containers/ProductQualityDashboardBuilder/ProductQualityDasboardBuilder"));
const SellerVideoBuilder = React.lazy(() => import("../containers/SellerVideoBuilder/SellerVideoBuilder"));
const VideoPlayerBuilder = React.lazy(() => import("../containers/VideoPlayerBuilder/VideoPlayerBuilder"));
const VendorVideoBuilder = React.lazy(() => import("../containers/VendorVideoBuilder/VendorVideoBuilder"));
const VendorVideoProductBuilder = React.lazy(() => import("../containers/VendorVideoProductBuilder/VendorVideoProductBuilder"));
const RtoBuilder = React.lazy(() => import("../containers/RtoBuilder/RtoBuilder"));
const OrderProcessingBuilder = React.lazy(() => import("../containers/OrderProcessingBuilder/OrderProcessingBuilder"));
const ManageCatalogBuilder = React.lazy(() => import("../containers/ManageCatalogBuilder/ManageCatalogBuilder"));
const PaymentRecoBuilder = React.lazy(() => import('../containers/PaymentRecoBuilder/PaymentRecoBuilder'));
const PaymentsBuilder = React.lazy(() => import("../containers/PaymentsBuilder/PaymentsBuilder"));
const PaymentReversalBuilder = React.lazy(() => import("../containers/PaymentReversalBuilder/PaymentReversalBuilder"));
const PaymentReversalComments = React.lazy(() => import("../containers/PaymentReversalBuilder/PaymentReversalComments/PaymentReversalComments"));
const UpdateEditCatalogBuilder = React.lazy(() => import("../containers/UpdateEditCatalogBuilder/UpdateEditCatalogBuilder"));
const SellerPanelBuilder = React.lazy(() => import("../containers/SellerPanelBuilder/SellerPanelBuilder"));
const UpdateMarketDataBuilder = React.lazy(() => import("../containers/UpdateMarketDataBuilder/UpdateMarketDataBuilder"));
const OnboardingBuilder = React.lazy(() => import("../containers/OnboardingBuilder/OnboardingBuilder"));
const UploadDocBuilder = React.lazy(() => import("../containers/OnboardingBuilder/UploadDocBuilder"));
const FeedbackBuilder = React.lazy(() => import("../containers/FeedbackBuilder/FeedbackBuilder"));
const NotifPanelBuilder = React.lazy(() => import("../containers/NotifPanelBuilder/NotifPanelBuilder"));
const ContactBuilder = React.lazy(() => import("../containers/ContactsBuilder/ContactBuilder"));
const NotificationPreference = React.lazy(() => import("../containers/NotificationPreference/NotificationPreference"));
const RTOPanelBuilder = React.lazy(() => import("../containers/RTOPanelBuilder/RTOPanelBuilder"));
const VendorOrdersBuilder = React.lazy(() => import("../containers/VendorOrdersBuilder/VendorOrdersBuilder"));
const ChangeWarehouseBuilder = React.lazy(() => import("../containers/ChangeWarehouseBuilder/ChangeWarehouseBuilder"));
const StoreInsightsBuilder = React.lazy(() => import("../containers/StoreInsightsBuilder/StoreInsightsBuilder"));
const PastOrdersFeedbacksBuilder = React.lazy(() => import("../containers/PastOrdersFeedbacksBuilder/PastOrdersFeedbacksBuilder"));
const CustomerATC = React.lazy(() => import("../containers/CustomerATC/CustomerATC"));
const QrPaymentsBuilder = React.lazy(() => import("../containers/QrPaymentsBuilder/QrPaymentsBuilder"));
const TrafficReportBuilder = React.lazy(() =>
  import("../containers/TrafficReportBuilder/TrafficReportBuilder")
);

// const AddReview = React.lazy(() => import('../components/AddReview'));
// const FeedbackQuestions = React.lazy(() => import('../components/FeedbackQuestions'));
// const Ticker = React.lazy(() => import('../components/Ticker'));
// const MarkOrderFeedback = React.lazy(() => import('../components/MarkOrderFeedback'))
import Loader from "../components/Loader/Loader";
import MainLoader from "./Loader/MainLoader";
// import Feedback from "../components/Feedback"
// import AddReview from "../components/AddReview"
// import FeedbackQuestions from "../components/FeedbackQuestions"
// import Ticker from "../components/Ticker"
import "./App.css";

const vendorMain = () => (
  <Suspense fallback={<Loader />}>
    <Vendor />
  </Suspense>
);
// const AddReviewComponent = () => (<Suspense fallback={<Loader/>}> <AddReview /> </Suspense> );
// const FeedbackQuestionsComponent = () => (<Suspense fallback={<Loader/>}> <FeedbackQuestions /> </Suspense> );
// const TickerComponent = () => (<Suspense fallback={<Loader/>}> <Ticker /> </Suspense> );
// const MarkOrderFeedbackComponent = () => (<Suspense fallback={<Loader/>}> <MarkOrderFeedback /> </Suspense> );
export default class App extends React.Component {

  state = {
    loginCheck: false,
    loggedIn: false
  };

  firebaseAnalytics = () => {
	if(window.location && window.location.href && ((window.location.href.indexOf("seller.trunkroute.com") > 0) || (window.location.href.indexOf("limeroadlive.com") > 0)) && !window.SellerFirebaseAnalytics && window.firebase &&  window.firebase.analytics && (typeof(window.firebase.analytics) === 'function')){
        window.SellerFirebaseAnalytics = window.firebase.analytics();
    }
  }

  logError = () => {
	  if(window) {
		window.onerror = function (msg, url, lineNo, columnNo, error) {
			// var string = msg.toLowerCase();
			// var substring = "script error";
			// if (string.indexOf(substring) > -1){
			//   alert('Script Error: See Browser Console for Detail');
			// } else {
			let message_obj = {
				message: msg,
				url: url,
				line: lineNo,
				column: columnNo,
				error_obj: JSON.stringify(error)
			}
			if(window && window.SellerFirebaseAnalytics && window.SellerFirebaseAnalytics.logEvent){
				var logData = {...message_obj};
				if(this.props && this.props.location && this.props.location.pathname){
					logData["pathname"] = this.props.location.pathname;
				}
				window.SellerFirebaseAnalytics.logEvent("error",logData);
			}
			// }
			return false;
		};
	  }
  }

  	componentDidMount(){
  		this.firebaseAnalytics();
		if(!!URLSearchParams && new URLSearchParams(window.location.href).get('versionName')){
			document.cookie = `twa_version=${!!URLSearchParams && new URLSearchParams(window.location.href).get('versionName')}`;
		}
		if(document && document.referrer && (document.referrer.includes('android-app://') || document.referrer.includes('versionName'))){
            sessionStorage.twa = "true";
			logServerEvent({name: "app_version"},{type: "twa", val: _getCookieValue("twa_version"), extra: ""},{type: ""});
        }
  		axios.get("/home/check_logged_in_user")
		.then(result => {
			this.setState({
				loginCheck: true,
				loggedIn: true
			});
			if(window.SellerFirebaseAnalytics && window.SellerFirebaseAnalytics.logEvent){
				var logData = (result && result.data)? {...result.data} : {};
				if(this.props && this.props.location && this.props.location.pathname){
					logData["pathname"] = this.props.location.pathname;
				}
				window.SellerFirebaseAnalytics.logEvent("vendor_details",logData);
				
				// check for twa 
				if(document && document.referrer && document.referrer.includes('android-app://')) {
					let loggedData = (result && result.data)? {...result.data} : {};
					if(this.props && this.props.location && this.props.location.pathname){
						loggedData["pathname"] = this.props.location.pathname;
					}
					loggedData = {...loggedData, twa: document.referrer.includes('android-app://')};
					window.SellerFirebaseAnalytics.logEvent("twa", loggedData);
				}
			}
		})
		.catch(error => {
			this.setState({
				loginCheck: true,
				loggedIn: false
			});
		});
		this.logError();
  	}
  
  render() {
  	const {loginCheck, loggedIn} = this.state;
  	console.log("test logs app.js");
    return (
    	<React.Fragment>
	    	{(loginCheck && loggedIn)? (
			<React.Fragment>
	    				    <Provider store={store}>	
	    				      <BrowserRouter>
	    				        <Switch>
	    				          <Route exact path="/newsell/test" component={vendorMain} />
	    				          <Route exact path="/newsell/home/select_vendor"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <SelectVendorBuilder {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route exact path="/newsell/videoplayer"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                  <VideoPlayerBuilder {...props}/>
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route exact path="/newsell/vendor_videos"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <VendorVideoBuilder {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route exact path="/newsell/product_quality" 
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <ProductQualityDashboardBuilder {...props}/>
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route exact path="/newsell/upload_video_product"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <SellerVideoBuilder {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
								  <Route exact path="/newsell/upload_product"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <SellerVideoBuilder {...props} noVideo={true}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
								  <Route exact path="/newsell/upload_all"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <VendorVideoProductBuilder {...props} newVideoUpload={true}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
								  <Route exact path="/newsell/edit_product"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <VendorVideoProductBuilder {...props} editProduct={true}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route exact path="/newsell/rto"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <RtoBuilder {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
	    						  <Route path="/newsell/vendor_inventory"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <ManageCatalogBuilder {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route path="/newsell/panel"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
										<PanelLayout {...props} >
	    				                	<SellerPanelBuilder {...props}/>
										</PanelLayout >
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route exact path="/newsell/home/addproducts"
									render={(props) => (
				                        <Suspense fallback={<Loader />}>
				                        <Layout {...props}>
				                            <UpdateEditCatalogBuilder {...props} />
				                        </Layout>
				                        </Suspense>
				                        )}
									/>
	    				          <Route path="/newsell/home"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <OrderProcessingBuilder {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route path="/newsell/payments"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <PaymentsBuilder {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route path="/newsell/payment_reversal"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <PaymentReversalBuilder {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route path="/newsell/payment_reversal_comments"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <PaymentReversalComments {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
	    				          <Route path="/newsell/update_market_data"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <UpdateMarketDataBuilder {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
								  <Route path="/newsell/payment_reco"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <Layout {...props}>
	    				                  <PaymentRecoBuilder {...props}/>
	    				                </Layout>
	    				              </Suspense>
	    				            )}
	    				          />
								   <Route path="/newsell/seller_feedbacks"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<FeedbackBuilder {...props}/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/onboarding"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<OnboardingBuilder {...props}/>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/upload_docs"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<UploadDocBuilder {...props}/>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/notif_panel"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<NotifPanelBuilder {...props}/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/rto_report"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<RTOPanelBuilder {...props}/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/test/contact"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<ContactBuilder {...props}/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/notif_preference"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<NotificationPreference {...props}/>
												</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/vendor_orders"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<VendorOrdersBuilder {...props}/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/change_warehouse"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<ChangeWarehouseBuilder {...props}/>
											</Layout>
										</Suspense>
									)}
								  />
								   <Route path="/newsell/store_insights"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<StoreInsightsBuilder {...props}/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/past_orders_and_feedbacks"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<PastOrdersFeedbacksBuilder {...props}/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/customer_atc"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<CustomerATC {...props} display="atc"/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/qr_code_leads"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<CustomerATC {...props} display="qr"/>
											</Layout>
										</Suspense>
									)}
								  />
								   <Route path="/newsell/look_alike_leads"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<CustomerATC {...props} display="look_alike"/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/my_contacts_leads"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<CustomerATC {...props} display="my_contacts"/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/payments_summary"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<QrPaymentsBuilder {...props}/>
											</Layout>
										</Suspense>
									)}
								  />
								  <Route path="/newsell/traffic_report"
									render={(props) => (
										<Suspense fallback={<Loader />}>
											<Layout {...props}>
												<TrafficReportBuilder {...props}/>
											</Layout>
										</Suspense>
									)}
								  />
	    				          <Route path="/"
	    				            render={(props) => (
	    				              <Suspense fallback={<Loader />}>
	    				                <LoginBuilder {...props}/>
	    				              </Suspense>
	    				            )}
	    				          />
	    				        </Switch>
	    				      </BrowserRouter>
	    				    </Provider>
					</React.Fragment>)
					:
	    			(<React.Fragment>
	    				{(!loginCheck)? 
	    					(<MainLoader />)
	    					:
	    					(
	    						<Provider store={store}>	
	    						  <BrowserRouter>
	    						    <Switch>
										<Route path="/newsell/onboarding"
											render={(props) => (
												<Suspense fallback={<Loader />}>
												  <OnboardingBuilder {...props}/>
												</Suspense>
											)}
										/>
		    							<Route path="/"
			    				        render={(props) => (
			    				          <Suspense fallback={<Loader />}>
			    				            <LoginBuilder {...props}/>
			    				          </Suspense>
			    				        )}
			    				     	/>
			    				     </Switch>
			    				    </BrowserRouter>
	    						</Provider>
	    					)
	    				}
	    			</React.Fragment>
	    		)
	    	}
    	</React.Fragment>
    );
  }
}
