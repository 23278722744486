// console.log("reducer.js");
import * as actionTypes from './actions';
// import a new file for method handlers

const initialState = {
	video_url: "",
	products: [],
}


const reducer = (state = initialState, action) => {
	switch(action.type){
		case actionTypes.SET_VIDEO_URL:
			return {
				...state,
				video_url: action.value
			}

		case actionTypes.ADD_PRODUCT:
			const newProduct = {
				category: "",
				style_code: "",
				auto_style_code: true,
				timestamp: "",
				mrp: "",
				selling_price: "",
				sizes: [],
				inventory: [],
				color: "",
				material: "",
				image_url: [],
				prod_desc: "",
				prod_name: "",
				colorOptions : [],
				materialOptions: [],
				sizeOptions : [],
				loadingCatInfo: false,
				isColorVariant: false,
				categoryId : "",
				templateId : "",
				hsncode : "",
				inputValueCat:""
			}
			return {
				...state,
				products: state.products.concat( newProduct )
			}

			case actionTypes.RESET_PRODUCT:
				let resetProduct = {
					category: "",
					style_code: "",
					auto_style_code: true,
					timestamp: "",
					mrp: "",
					selling_price: "",
					sizes: [],
					inventory: [],
					color: "",
					material: "",
					image_url: [],
					prod_desc: "",
					prod_name: "",
					colorOptions : [],
					materialOptions: [],
					sizeOptions : [],
					loadingCatInfo: false,
					isColorVariant: false,
					categoryId : "",
					templateId : "",
					hsncode : "",
					inputValueCat:""
				}
				let new_products = [...state.products];
				new_products[action.index] = resetProduct;
				return {
					...state,
					products: new_products
				}

		case actionTypes.CLONE_PRODUCT:
			let productToCopy = state.products.filter((product, index) => index === action.index);
			productToCopy = {...productToCopy[0]};
			productToCopy.image_url = [];
			productToCopy.style_code = "";
			productToCopy.isColorVariant = false;
			return {
				...state,
				products: state.products.concat( productToCopy )
			}

		case actionTypes.ADD_COLOR_VARIANT:
			let colorVariantCopy = state.products.filter((product, index) => index === action.index);
			colorVariantCopy = {...colorVariantCopy[0]};
			colorVariantCopy.image_url = [];
			colorVariantCopy.color = "";
			colorVariantCopy.isColorVariant = true;
			return {
				...state,
				products: state.products.concat( colorVariantCopy )
			}

		case actionTypes.ADD_SIZE:
			let sizeToConcat = "";
			if(state.products && state.products[action.prodIndex] && state.products[action.prodIndex].sizeOptions) {
				let sizeOpt = state.products[action.prodIndex].sizeOptions;
				sizeToConcat = (sizeOpt.length === 1) ? sizeOpt[0] : "";
			}
			return {
				...state,
				products: state.products.map((product, index) => {
					if (index !== action.prodIndex) {
						return product
					}
					return {
						...product,
						sizes: [...product.sizes].concat(sizeToConcat),
						inventory: [...product.inventory].concat("")
					}
				})
			}
		case actionTypes.CHANGE_SIZE:
			return {
				...state,
				products: state.products.map((product, index) => {
					if (index !== action.prodIndex) {
						return product
					}
					return {
						...product,
						[action.key]: [...product[action.key]].map((sivalue, idx) => {
							if (idx !== action.sizeIndex){
								return sivalue
							}
							return action.value
						})
					}
				})
			}
		case actionTypes.DELETE_SIZE:
			return {
				...state,
				products: state.products.map((product, index) => {
					if (index !== action.prodIndex) {
						return product
					}
					return {
						...product,
						sizes: product.sizes.filter((size,index) => index !== action.sizeIndex),
						inventory: product.inventory.filter((inventory,index) => index !== action.sizeIndex)
					}
				})
			}
		case actionTypes.CHANGE_PRODUCT_VALUE:
			if(action.key === "image_url" || action.key === "sizes" || action.key === "inventory"){
				return {
					...state,
					products: state.products.map((product, index) => {
						if (index !== action.index) {
							return product
						}
						return {
							...product,
							[action.key]: [...product[action.key]].concat(action.value)
						}
					})
				}
			}

			else if(action.key === "fetch_cat_info"){
				return {
					...state,
					products: state.products.map((product, index) => {
						if (index !== action.index) {
							return product
						}
						return {
							...product,
							['colorOptions']: action.value.color,
							['materialOptions']: action.value.material,
							['sizeOptions']: action.value.size,
							['loadingCatInfo']: false,
							['categoryId'] : action.value.categoryId,
							['templateId'] : action.value.templateId,
						}
					})
				}
			}

			return {
				...state,
				products: state.products.map((product, index) => {
					if (index !== action.index) {
						// This isn't the item we care about - keep it as-is
						return product
					}
					return {
						...product,
						[action.key]: action.value
					}
				})
			}

		case actionTypes.CHANGE_PRODUCT_MULTIVALUE:
			let k = {
				...state,
				products: state.products.map((product, index) => {
					if (index !== action.index) {
						// This isn't the item we care about - keep it as-is
						return product
					}
					return {
						...product,
						...action.value
					}
				})
			}
			return k;

		case actionTypes.CHANGE_LOADING_STATE:
			return {
				...state,
				products: state.products.map((product, index) => {
					if (index !== action.prodIndex) {
						// This isn't the item we care about - keep it as-is
						return product
					}
					return {
						...product,
						['loadingCatInfo']: action.stateVal
					}
				})
			}

		case actionTypes.DELETE_PRODUCT:
			const newProducts = state.products.filter((product, index) => index !== action.index);
			const newstate = {
				...state,
				products: newProducts
			}
			return newstate
		case actionTypes.DELETE_IMAGE:
			return {
				...state,
				products: state.products.map((product, index) => {
					if (index !== action.prodIndex) {
						return product
					}
					return {
						...product,
						image_url: product.image_url.filter((image,index) => index !== action.imageIndex)
					}
				})
			}
		default: {
			return {
				...state
			}
		}
		return state;
	}
}
// console.log("reducerexport",reducer);

export default reducer;
